export const HEADER_HEIGHT = 70
export const BASE_URL = process.env.REACT_APP_BASE_URL ?? ''
export const FOUNDATION_URL = process.env.REACT_APP_FOUNDATION_URL ?? ''
// export const BASE_URL_HEALTH = process.env.REACT_APP_HEALTH_URL ?? ''
export const BASE_URL_HEALTH = process.env.REACT_APP_HEALTH_URL ?? ''
export const ACL_URL = process.env.REACT_APP_ACL_URL ?? ''
export const EQUITAS_URL = process.env.REACT_APP_EQUITAS_URL ?? ''
export const LEADS_URL = process.env.REACT_APP_LEADS_URL ?? ''
// export const BASE_URL_HEALTH = 'https://suraksha-uat.zopperinsurance.com'
export const MISC_URL = 'http://misc.theblackswan.in'
export { API_ENDPOINTS } from './endpoints'
export const LIFE_URL = process.env.REACT_APP_LIFE_URL ?? ''
export const PRODUCT_URL = process.env.REACT_APP_PRODUCT_URL ?? ''

export const saodEnableDate = new Date('2018/08/31')

export const nomineeMandatoryInsurers = [
  'TATA2W',
  'MG4W',
  'MG2W',
  'GDPCV',
  'hdfc_4w',
  'hdfc_2w',
  'HDGCV',
  'HE4W',
  'HE2W',
  'HDFCPC',
]

let today: any = new Date()
today = today.toISOString().split('T')[0]

export const sachetsCoverageConditions: any = {
  PMSBY: [
    "I hereby give my consent to become a member of 'Pradhan Mantri Suraksha Bima Yojana' of Chola MS General Insurance Company Limited which will be administered by your Bank under Master Policy No. 2879/00000005/000/00.",
    'I understand that the overall Sum-Insured of the policy for insured is Rs.2 Lakhs per person.',
    'I understand that this is a Group Personal Accident Insurance policy covering all the Savings Bank Account Holders in Equitas Small Finance Bank in the age group between 18 (completed) and 70 years (age nearer birthday) and declared for insurance coverage against death, permanent and partial disability from accident.',
    'I understand that the premium is Rs. 20 per annum per member.',
    "I understand that the premium will be deducted from the account holder’s saving bank account through 'auto debit’ facility in one instalment on or before 1st June of each annual coverage period under the scheme. However, in cases where auto debit takes place after 1st June, the cover shall commence from the first day of the month following the auto debit.",
    'I hereby authorize you to debit my Savings Bank Account with your Branch as per the plan details for the remainder of the year along with Taxes and Other Levies if applicable towards premium of Accident Cover under PMSBY. I further authorize you to deduct in future after 25th May and not later than on 1st of June every year until further instructions, an amount of Rs.20/- (Rupees Twenty only) plus Taxes and other Levies if applicable, or any amount as decided from time to time, which may be intimated immediately if and when revised, towards renewal of coverage under the scheme.',
    'I have not authorized any other bank to debit premium in respect of this scheme. I am aware that my Accident Cover shall be restricted to a max of Rs.2,00,000/- only in the event of my accidental death/ total and irrecoverable loss of one/both eyes/hands/feet.',
    'I have read and understood the Scheme rules and I hereby give my consent to become a member of the Scheme.',
    'I hereby nominate my nominee as provided in the digital form under this scheme. Nominee being minor, his / her guardian is appointed as above.',
    'I understand that upon the payment of the Death Benefit, the Policy terminates and no further benefits are payable.',
    'I am aware that at any point in time, there could be only one active PMSBY scheme against my life. I hereby declare that I don’t have any active PMSBY scheme against my life, with any other bank or with any other insurer.',
    'I authorize the Bank to convey my personal details, provided in the digital form, as required, regarding my admission into the group insurance scheme to Chola MS General.',
  ],
  PMJJBY: [
    'I, hereby give my consent to become a member of ‘HDFC Life Pradhan Mantri Jeevan Jyoti Bima Yojana Plan’ of HDFCLife which will be administered by your Bank under Master Policy No.PM000009.',
    'I hereby authorize you to debit my Savings Bank Account with your Branch as per the plan details for the remainder of the year along with Taxes and Other Levies if applicable towards premium of life cover under PMJJBY. I further authorize you to deduct in future after 25th May and not later than on 1st of June every year until further instructions, an amount of Rs.436/- (Rupees four hundred thirty six only) plus Taxes and other Levies if applicable, or any amount as decided from time to time, which may be intimated immediately if and when revised, towards renewal of coverage under the scheme.',
    'I have not authorized any other bank to debit premium in respect of this scheme. I am aware that my life cover shall be restricted to Rs.2,00,000/- only in the event of my death.',
    'I have read and understood the Scheme rules and I hereby give my consent to become a member of the Scheme.',
    `I hereby declare that I am in sound health and am not suffering or have suffered from any critical illness or condition requiring medical treatment, as on date. ${today} (critical illness is defined as follows: The applicant should not have suffered / be suffering from AIDS, cancer, condition requiring open chest surgery, history of typical chest pain, kidney failure, brain stroke or paralysis or having undergone a major organ transplantation such as heart, lung, liver or kidney. If the applicant had suffered from any of the above critical illness, they are not eligible to join the scheme).`,
    'I authorize the Bank to convey my personal details, provided in the digital form, as required, regarding my admission into the group insurance scheme to HDFC Life.',
    'I hereby nominate my nominee as provided in the digital form under this scheme. Nominee being minor, his / her guardian is appointed as above.',
    'I understand that the risk will not be covered during the first 30 days from the date of enrolment into the scheme (lien period) and in case of death (other than due to accident) during lien period, no claim would be admissible.',
    'I understand that upon the payment of the Death Benefit, the Policy terminates and no further benefits are payable.',
    'I am aware that at any point in time, there could be only one active PMJJBY scheme against my life. I hereby declare that I don’t have any active PMJJBY scheme against my life, with any other bank or with any other insurer.',
  ],
}

export const sachetsTerminationClauses: any = {
  PMJJBY: [
    // 'The customer consent link does not expire. The user is able to open the consent form when they click the link via email/SMS after it has been approved.',
    'On attaining age 55 years.',
    'Closure of account with the Bank or insufficiency of balance to keep the policy in force or any other reasons leading to non-renewal of policy (including but not limited to debit freeze/ Half KYC status at the time of renewal etc).',
    'In case a member is covered through more than one account and premium is received by HDFC Life inadvertently, insurance cover will be restricted to one only and the premium shall be liable to be forfeited.',
  ],
  PMSBY: [
    // 'The customer consent link does not expire. The user is able to open the consent form when they click the link via email/SMS after it has been approved.',
    'On attaining age 70 years.',
    'Closure of account with the Bank or insufficiency of balance to keep the insurance in force or any other reasons leading to non-renewal of policy (including but not limited to debit freeze/ Half KYC status at the time of renewal etc).',
    'In case a member is covered through more than one account and premium is received by Chola MS General Insurance inadvertently, insurance cover will be restricted to one only and the premium shall be liable to be forfeited.',
    'If the insurance cover is ceased due to any technical reasons such as insufficient balance on due date or due to any administrative issues, the same can be reinstated on receipt of full annual premium, subject to conditions that may be laid down. During this period, the risk cover will be suspended and reinstatement of risk cover will be at the sole discretion of Insurance Company.',
  ],
}

export const hypothecationCheckInsurers = ['Chola4', 'Chola2', 'HDGCV', 'HDFCPC', 'HE4W', 'HE2W']

export const dotNotInclude = /^[^\.]*$/

export const GenderMap: any = {
  son: 'Male',
  daughter: 'Female',
  father: 'Male',
  mother: 'Female',
  spouse: 'Female',
}

export const allInsurers = {
  digit_fw: 'GoDigit Four wheeler',
  digit_tw: 'GoDigit 2w',
  hdfc_4w: 'HDFC ERGO General Insurance Company Four Wheeler',
  hdfc_2w: 'HDFC ERGO General Insurance Company Two Wheeler',
  HDGCV: 'HDFC GCV',
  HDPCV: 'HDFC PCV',
  chola_4w: 'Cholamandalam MS General Insurance',
  raheja_4w: 'Raheja 4W',
  religare_name: 'RELIGARE',
  navi_4w: 'Navi General Fourwheeler',
  navi_2w: 'Navi General Twowheeler',
  tata_aig_4w: 'TATA AIG 4W',
  tata_aig_2w: 'TATA AIG 2W',
  SBIFW: 'SBI General Insurance Four Wheeler',
  SBITW: 'SBI General Insurance Two Wheeler',
  SBIGCV: 'SBI General Insurance GCV',
  BJ4W: 'Bajaj 4W',
  BJ2W: 'Bajaj 2W',
  BJGCV: 'Bajaj Good Carrying Vehicle',
}

/**
 * ---------------------- Master Sheet Start ----------------------
 */

export const DONT_SHOW_ACCT_STATUS_MASTER = [
  { cod_acct_status: 1, acct_status: 'ACCOUNT CLOSED' },
  { cod_acct_status: 2, acct_status: 'ACCOUNT BLOCKED' },
  { cod_acct_status: 5, acct_status: 'ACCOUNT CLOSED TODAY' },
  { cod_acct_status: 10, acct_status: 'ACCOUNT BLOCKED DORMANT' },
  { cod_acct_status: 13, acct_status: 'ACCOUNT INOPERATIVE BLOCKED' },
  { cod_acct_status: 21, acct_status: 'UNCLAIMED ACCOUNT' },
  { cod_acct_status: 4, acct_status: 'ACCOUNT UNCLAIMED' },
  { cod_acct_status: 9, acct_status: 'ACCOUNT BLOCKED' },
  { cod_acct_status: 21, acct_status: 'ACCOUNT BLOCKED' },
  { cod_acct_status: 11, acct_status: 'ACCOUNT WRITTEN OFF CLOSED' },
  { cod_acct_status: 12, acct_status: 'ACCOUNT PAID OFF CLOSED' },
  { cod_acct_status: 20, acct_status: 'ACCOUNT SETTLED - PENDING CHEQUE' },
  { cod_acct_status: 24, acct_status: 'ACCOUNT TRANSFERRED' },
  { cod_acct_status: 22, acct_status: 'PRODUCT TRANSFERRED' },
  { cod_acct_status: 25, acct_status: 'ACCOUNT DISCHARGED PARTLY' },
  { cod_acct_status: 26, acct_status: 'ACCOUNT DISCHARGED FULLY' },
  { cod_acct_status: 10, acct_status: 'ACCOUNT WRITTEN OFF OPEN' },
]

export const DIRECT_DEBIT_MASTER = [4, 6, 8, 17]

export const PAYMENT_GATEWAY_MASTER = [
  3, 7, 9, 11, 12, 14, 15, 16, 18, 19, 20, 2, 3, 10, 15, 22, 16,
]

export const getMasterPolicyNumber: any = {
  PMJJBY: 'PM000009',
  PMSBY: '2879/00000005/000/00',
  CIC: '50747600202400',
}

export const getStateName: any = {
  '10': 'ANDAMAN and NICOBAR ISLANDS',
  '11': 'ANDHRA PRADESH',
  '12': 'ARUNACHAL PRADESH',
  '13': 'ASSAM',
  '14': 'BIHAR',
  '15': 'CHANDIGARH',
  '16': 'CHATTISGARH',
  '17': 'DADRA and NAGAR HAVELI',
  '18': 'DAMAN and DIU',
  '19': 'DELHI',
  '20': 'GOA',
  '21': 'GUJARAT',
  '22': 'HARYANA',
  '23': 'HIMACHAL PRADESH',
  '24': 'JAMMU and KASHMIR',
  '25': 'JHARKHAND',
  '26': 'KARNATAKA',
  '27': 'KERALA',
  '28': 'LAKSHADWEEP',
  '29': 'MADHYA PRADESH',
  '30': 'MAHARASHTRA',
  '31': 'MANIPUR',
  '32': 'MEGHALAYA',
  '33': 'MIZORAM',
  '34': 'NAGALAND',
  '35': 'ODISHA',
  '36': 'PONDICHERRY',
  '37': 'PUNJAB',
  '38': 'RAJASTHAN',
  '39': 'SIKKIM',
  '40': 'TAMIL NADU',
  '42': 'TRIPURA',
  '43': 'UTTAR PRADESH',
  '44': 'UTTARAKHAND',
  '45': 'WEST BENGAL',
  '46': 'TELANGANA',
  '500': 'ERRORSTATE',
  '593': '.',
  '1314': 'CHHATTISGARH',
}

/**
 * ---------------------- Master Sheet End ----------------------
 */

/**
 * ---------------------- Product Sheet Start ----------------------
 */

export const DONT_SHOW_ACCT_STATUS_PRODUCT = [
  1027, 1028, 1035, 1040, 1043, 1045, 1057, 1950, 2007, 2008,
]

export const PAYMENT_GATEWAY_PRODUCT = [
  1033, 1034, 1038, 1051, 1052, 2015, 3001, 3002, 3003, 3008, 3009, 3011, 3012, 3013, 3018, 3019,
  3021, 3022, 3023, 3028, 3029, 3031, 3032, 3033, 3034, 3035, 3036, 3038, 3039, 3041, 3042, 3043,
  3044, 3045, 3046, 3048, 3049, 3051, 3052, 3053, 3054, 3055, 3056, 3058, 3059, 3060, 3061, 3062,
  3064, 3065, 3066, 3067, 3068, 3069, 3070, 3072, 3073, 3074, 3075, 3076, 3077, 3078, 3079, 3080,
  3081, 3082, 3083, 3084, 3085, 3086, 3087, 3088, 3089, 3090, 3091, 3092, 3093, 3094, 3095, 3096,
  3097, 3098, 3099, 3100, 3101, 3102, 3103, 3104, 3105, 3106, 3107, 3108, 3109, 3110, 3111, 3112,
  3113, 3114, 3115, 3116, 3117, 3118, 3119, 3123, 3124, 3125, 4001, 4002, 4003, 4004, 4005, 4006,
  4007, 4008, 9102,
]

export const DIRECT_DEBIT_PRODUCT = [
  1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011, 1012, 1013, 1014, 1015, 1016,
  1017, 1018, 1019, 1020, 1021, 1022, 1023, 1024, 1025, 1026, 1029, 1030, 1031, 1032, 1037, 1039,
  1044, 1046, 1047, 1048, 1053, 1054, 1055, 1058, 1059, 1060, 1999, 2001, 2002, 2003, 2004, 2005,
  2006, 2009, 2010, 2011, 2012, 2013, 2014, 2016, 2017, 2019, 2020, 2022, 2023, 2024, 2027, 2028,
  2031, 2035, 2037, 9101,
]

/**
 * ---------------------- Product Sheet End ----------------------
 */

/**
 * ---------------------- Account Relationship Sheet Start ----------------------
 */

export const DIRECT_DEBIT_ACCOUNT_RELATIONSHIP = ['AUS', 'GUR', 'JOF', 'JOO', 'SOW']

/**
 * ---------------------- Account Relationship Sheet End ----------------------
 */

export { STORAGE_CONSTANTS } from './storage'
export {
  registrationNumRegex,
  mobileNumRegex,
  emailRegex,
  panNumRegex,
  gstRegex,
  onlyAlphabetsRegex,
  onlyNumbersRegex,
} from './regex'

// Accounts Mapping

const accountMapping: any = {
  '1': 'ACCOUNT CLOSED',
  '2': 'ACCOUNT BLOCKED',
  '3': 'ACCOUNT OPEN - NO DEBIT',
  '4': 'ACCOUNT OPEN - NO CREDIT',
  '5': 'ACCOUNT CLOSED TODAY',
  '6': 'ACCOUNT OPENED TODAY',
  '7': 'ACCOUNT DORMANT',
  '8': 'ACCOUNT OPEN REGULAR',
}

export const getCountryName = (code: string) => {
  // switch (code) {
  //   case '1':
  //     return 'UNITED ARAB EMIRATES'
  //   case '2':
  //     return 'AFGHANISTAN'
  //   case '3':
  //     return 'ANGUILLA'
  //   case '4':
  //     return 'Algeria'
  //   case '5':
  //     return 'American Samoa'
  //   case '6':
  //     return 'INDIA'
  //   case '7':
  //     return 'Angola'
  //   case '8':
  //     return 'Anguilla'
  //   case '9':
  //     return 'Antigua and Barbuda'
  //   case '10':
  //     return 'Argentina'
  //   case '11':
  //     return 'Armenia'
  //   case '12':
  //     return 'Aruba'
  //   case '13':
  //     return 'Australia'
  //   case '14':
  //     return 'Austria'
  //   case '15':
  //     return 'Azerbaijan'
  //   case '16':
  //     return 'Bahamas'
  //   case '17':
  //     return 'Bahrain'
  //   case '18':
  //     return 'Bangladesh'
  //   case '19':
  //     return 'Barbados'
  //   case '20':
  //     return 'Belarus'
  //   case '21':
  //     return 'Belgium'
  //   case '22':
  //     return 'Belize'
  //   case '23':
  //     return 'Benin'
  //   case '24':
  //     return 'Bermuda'
  //   case '25':
  //     return 'Bhutan'
  //   case '26':
  //     return 'Bolivia'
  //   case '27':
  //     return 'Bosnia and Herzegovina'
  //   case '28':
  //     return 'Botswana'
  //   case '29':
  //     return 'Brazil'
  //   case '30':
  //     return 'British Virgin Islands'
  //   case '31':
  //     return 'Brunei Darussalam'
  //   case '32':
  //     return 'Bulgaria'
  //   case '33':
  //     return 'Burkina Faso'
  //   case '34':
  //     return 'Burundi'
  //   case '35':
  //     return 'Cambodia'
  //   case '36':
  //     return 'Cameroon'
  //   case '37':
  //     return 'Canada'
  //   case '38':
  //     return 'Cape Verde'
  //   case '39':
  //     return 'Cayman Islands'
  //   case '40':
  //     return 'Central African Republic'
  //   case '41':
  //     return 'Chad'
  //   case '42':
  //     return 'Chile'
  //   case '43':
  //     return 'China'
  //   case '44':
  //     return 'Hong Kong Special Administrative Region of China'
  //   case '45':
  //     return 'Macao Special Administrative Region of China'
  //   case '46':
  //     return 'Colombia'
  //   case '47':
  //     return 'Comoros'
  //   case '48':
  //     return 'Congo'
  //   case '49':
  //     return 'Cook Islands'
  //   case '50':
  //     return 'Costa Rica'
  //   case '51':
  //     return 'Côte d Ivoire'
  //   case '52':
  //     return 'Croatia'
  //   case '53':
  //     return 'Cuba'
  //   case '54':
  //     return 'Cyprus'
  //   case '55':
  //     return 'Czech Republic'
  //   case '56':
  //     return 'Democratic People Republic of Korea'
  //   case '57':
  //     return 'Democratic Republic of the Congo'
  //   case '58':
  //     return 'Denmark'
  //   case '59':
  //     return 'Djibouti'
  //   case '60':
  //     return 'Dominica'
  //   case '61':
  //     return 'Dominican Republic'
  //   case '62':
  //     return 'Ecuador'
  //   case '63':
  //     return 'Egypt'
  //   case '64':
  //     return 'El Salvador'
  //   case '65':
  //     return 'Equatorial Guinea'
  //   case '66':
  //     return 'Eritrea'
  //   case '67':
  //     return 'Estonia'
  //   case '68':
  //     return 'Ethiopia'
  //   case '69':
  //     return 'Faeroe Islands'
  //   case '70':
  //     return 'Falkland Islands (Malvinas)'
  //   case '71':
  //     return 'Fiji'
  //   case '72':
  //     return 'Finland'
  //   case '73':
  //     return 'France'
  //   case '74':
  //     return 'French Guiana'
  //   case '75':
  //     return 'French Polynesia'
  //   case '76':
  //     return 'Gabon'
  //   case '77':
  //     return 'Gambia'
  //   case '78':
  //     return 'Georgia'
  //   case '79':
  //     return 'Germany'
  //   case '80':
  //     return 'Ghana'
  //   case '81':
  //     return 'Gibraltar'
  //   case '82':
  //     return 'Greece'
  //   case '83':
  //     return 'Greenland'
  //   case '84':
  //     return 'Grenada'
  //   case '85':
  //     return 'Guadeloupe'
  //   case '86':
  //     return 'Guam'
  //   case '87':
  //     return 'Guatemala'
  //   case '88':
  //     return 'Guernsey'
  //   case '89':
  //     return 'Guinea'
  //   case '90':
  //     return 'Guinea-Bissau'
  //   case '91':
  //     return 'Guyana'
  //   case '92':
  //     return 'Haiti'
  //   case '93':
  //     return 'Holy See'
  //   case '94':
  //     return 'Honduras'
  //   case '95':
  //     return 'Hungary'
  //   case '96':
  //     return 'Iceland'
  //   case '97':
  //     return 'Andorra'
  //   case '98':
  //     return 'Indonesia'
  //   case '99':
  //     return 'Iran'
  //   case '100':
  //     return 'Iraq'
  //   case '101':
  //     return 'Ireland'
  //   case '102':
  //     return 'Isle of Man'
  //   case '103':
  //     return 'Israel'
  //   case '104':
  //     return 'Italy'
  //   case '105':
  //     return 'Jamaica'
  //   case '106':
  //     return 'Japan'
  //   case '107':
  //     return 'Jersey'
  //   case '108':
  //     return 'Jordan'
  //   case '109':
  //     return 'Kazakhstan'
  //   case '110':
  //     return 'Kenya'
  //   case '111':
  //     return 'Kiribati'
  //   case '112':
  //     return 'Kuwait'
  //   case '113':
  //     return 'Kyrgyzstan'
  //   case '114':
  //     return 'Lao People Democratic Republic'
  //   case '115':
  //     return 'Latvia'
  //   case '116':
  //     return 'Lebanon'
  //   case '117':
  //     return 'Lesotho'
  //   case '118':
  //     return 'Liberia'
  //   case '119':
  //     return 'Libyan Arab Jamahiriya'
  //   case '120':
  //     return 'Liechtenstein'
  //   case '121':
  //     return 'Lithuania'
  //   case '122':
  //     return 'Luxembourg'
  //   case '123':
  //     return 'Madagascar'
  //   case '124':
  //     return 'Malawi'
  //   case '125':
  //     return 'Malaysia'
  //   case '126':
  //     return 'Maldives'
  //   case '127':
  //     return 'Mali'
  //   case '128':
  //     return 'Malta'
  //   case '129':
  //     return 'Marshall Islands'
  //   case '130':
  //     return 'Martinique'
  //   case '131':
  //     return 'Mauritania'
  //   case '132':
  //     return 'Mauritius'
  //   case '133':
  //     return 'Mayotte'
  //   case '134':
  //     return 'Mexico'
  //   case '135':
  //     return 'Micronesia'
  //   case '136':
  //     return 'Moldova'
  //   case '137':
  //     return 'Monaco'
  //   case '138':
  //     return 'Mongolia'
  //   case '139':
  //     return 'Montenegro'
  //   case '140':
  //     return 'Montserrat'
  //   case '141':
  //     return 'Morocco'
  //   case '142':
  //     return 'Mozambique'
  //   case '143':
  //     return 'Myanmar'
  //   case '144':
  //     return 'Namibia'
  //   case '145':
  //     return 'Nauru'
  //   case '146':
  //     return 'Nepal'
  //   case '147':
  //     return 'Netherlands'
  //   case '148':
  //     return 'Netherlands Antilles'
  //   case '149':
  //     return 'New Caledonia'
  //   case '150':
  //     return 'New Zealand'
  //   case '151':
  //     return 'Nicaragua'
  //   case '152':
  //     return 'Niger'
  //   case '153':
  //     return 'Nigeria'
  //   case '154':
  //     return 'Niue'
  //   case '155':
  //     return 'Norfolk Island'
  //   case '156':
  //     return 'Northern Mariana Islands'
  //   case '157':
  //     return 'Norway'
  //   case '158':
  //     return 'Occupied Palestinian Territory'
  //   case '159':
  //     return 'Oman'
  //   case '160':
  //     return 'Pakistan'
  //   case '161':
  //     return 'Palau'
  //   case '162':
  //     return 'Panama'
  //   case '163':
  //     return 'Papua New Guinea'
  //   case '164':
  //     return 'Paraguay'
  //   case '165':
  //     return 'Peru'
  //   case '166':
  //     return 'Philippines'
  //   case '167':
  //     return 'Pitcairn'
  //   case '168':
  //     return 'Poland'
  //   case '169':
  //     return 'Portugal'
  //   case '170':
  //     return 'Puerto Rico'
  //   case '171':
  //     return 'Qatar'
  //   case '172':
  //     return 'Republic of Korea'
  //   case '173':
  //     return 'R_union'
  //   case '174':
  //     return 'Romania'
  //   case '175':
  //     return 'Russian Federation'
  //   case '176':
  //     return 'Rwanda'
  //   case '177':
  //     return 'Saint-Barthélemy'
  //   case '178':
  //     return 'Saint Helena'
  //   case '179':
  //     return 'Saint Kitts and Nevis'
  //   case '180':
  //     return 'Saint Lucia'
  //   case '181':
  //     return 'Saint-Martin (French part)'
  //   case '182':
  //     return 'Saint Pierre and Miquelon'
  //   case '183':
  //     return 'Saint Vincent and the Grenadines'
  //   case '184':
  //     return 'Samoa'
  //   case '185':
  //     return 'San Marino'
  //   case '186':
  //     return 'Sao Tome and Principe'
  //   case '187':
  //     return 'Saudi Arabia'
  //   case '188':
  //     return 'Senegal'
  //   case '189':
  //     return 'Serbia'
  //   case '190':
  //     return 'Seychelles'
  //   case '191':
  //     return 'Sierra Leone'
  //   case '192':
  //     return 'Singapore'
  //   case '193':
  //     return 'Slovakia'
  //   case '194':
  //     return 'Slovenia'
  //   case '195':
  //     return 'Solomon Islands'
  //   case '196':
  //     return 'Somalia'
  //   case '197':
  //     return 'South Africa'
  //   case '198':
  //     return 'Spain'
  //   case '199':
  //     return 'Sri Lanka'
  //   case '200':
  //     return 'Sudan'
  //   case '201':
  //     return 'Suriname'
  //   case '202':
  //     return 'Svalbard and Jan Mayen Islands'
  //   case '203':
  //     return 'Swaziland'
  //   case '204':
  //     return 'Sweden'
  //   case '205':
  //     return 'Switzerland'
  //   case '206':
  //     return 'Syrian Arab Republic'
  //   case '207':
  //     return 'Tajikistan'
  //   case '208':
  //     return 'Thailand'
  //   case '209':
  //     return 'The former Yugoslav Republic of Macedonia'
  //   case '210':
  //     return 'Timor-Leste'
  //   case '211':
  //     return 'Togo'
  //   case '212':
  //     return 'Tokelau'
  //   case '213':
  //     return 'Tonga'
  //   case '214':
  //     return 'Trinidad and Tobago'
  //   case '215':
  //     return 'Tunisia'
  //   case '216':
  //     return 'Turkey'
  //   case '217':
  //     return 'Turkmenistan'
  //   case '218':
  //     return 'Turks and Caicos Islands'
  //   case '219':
  //     return 'Tuvalu'
  //   case '220':
  //     return 'Uganda'
  //   case '221':
  //     return 'Ukraine'
  //   case '222':
  //     return 'United Arab Emirates'
  //   case '223':
  //     return 'United Kingdom of Great Britain and Northern'
  //   case '224':
  //     return 'United Republic of Tanzania'
  //   case '225':
  //     return 'United States of America'
  //   case '226':
  //     return 'United States Virgin Islands'
  //   case '227':
  //     return 'Uruguay'
  //   case '228':
  //     return 'Uzbekistan'
  //   case '229':
  //     return 'Vanuatu'
  //   case '230':
  //     return 'Venezuela (Bolivarian Republic of)'
  //   case '231':
  //     return 'Viet Nam'
  //   case '232':
  //     return 'Wallis and Futuna Islands'
  //   case '233':
  //     return 'Western Sahara'
  //   case '234':
  //     return 'Yemen'
  //   case '235':
  //     return 'Zambia'
  //   case '236':
  //     return 'Zimbabwe'
  //   case '237':
  //     return 'United Kingdom of Great Britain'
  //   case '238':
  //     return 'UNITED KINGDOM'
  //   case '239':
  //     return 'South Korea'
  //   case '240':
  //     return 'SCOTLAND'
  //   case '241':
  //     return 'Taiwan'
  //   case '242':
  //     return 'South Sudan'
  //   case '243':
  //     return 'RUSSIA'
  //   default:
  //     return 'Unknown Country'
  // }
  let country_name

  switch (parseInt(code)) {
    case 1:
      return 'UNITED ARAB EMIRATES'
    case 2:
      return 'AFGHANISTAN'
    case 3:
      return 'ANGUILLA'
    case 4:
      return 'ANGOLA'
    case 5:
      return 'ARGENTINA'
    case 6:
      return 'AUSTRIA'
    case 7:
      return 'AUSTRALIA'
    case 8:
      return 'AZERBAIJAN'
    case 9:
      return 'BARBADOS'
    case 10:
      return 'BANGLADESH'
    case 11:
      return 'BELGIUM'
    case 12:
      return 'BURKINA FASO'
    case 13:
      return 'BAHRAIN'
    case 14:
      return 'BENIN'
    case 15:
      return 'BERMUDA'
    case 16:
      return 'BRUNEI DARUSSALAM'
    case 17:
      return 'BOLIVIA (PLURINATIONAL STATE OF)'
    case 18:
      return 'BRAZIL'
    case 19:
      return 'BAHAMAS'
    case 20:
      return 'BOTSWANA'
    case 21:
      return 'BELARUS'
    case 22:
      return 'BELIZE'
    case 23:
      return 'CANADA'
    case 24:
      return 'CONGO (DEMOCRATIC REPUBLIC OF THE)'
    case 25:
      return 'CONGO'
    case 26:
      return 'SWITZERLAND'
    case 27:
      return "COTE D'IVOIRE"
    case 28:
      return 'CAMEROON'
    case 29:
      return 'CHINA'
    case 30:
      return 'COSTA RICA'
    case 31:
      return 'CYPRUS'
    case 32:
      return 'CZECH REPUBLIC'
    case 33:
      return 'GERMANY'
    case 34:
      return 'DJIBOUTI'
    case 35:
      return 'DENMARK'
    case 36:
      return 'ALGERIA'
    case 37:
      return 'ESTONIA'
    case 38:
      return 'EGYPT'
    case 39:
      return 'SPAIN'
    case 40:
      return 'ETHIOPIA'
    case 41:
      return 'FINLAND'
    case 42:
      return 'FRANCE'
    case 43:
      return 'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND'
    case 44:
      return 'GEORGIA'
    case 45:
      return 'GHANA'
    case 46:
      return 'GIBRALTAR'
    case 47:
      return 'GREENLAND'
    case 48:
      return 'GAMBIA'
    case 49:
      return 'GUINEA'
    case 50:
      return 'EQUATORIAL GUINEA'
    case 51:
      return 'GREECE'
    case 52:
      return 'GUYANA'
    case 53:
      return 'HONG KONG'
    case 54:
      return 'HONDURAS'
    case 55:
      return 'CROATIA'
    case 56:
      return 'HAITI'
    case 57:
      return 'HUNGARY'
    case 58:
      return 'INDONESIA'
    case 59:
      return 'IRELAND'
    case 60:
      return 'ISRAEL'
    case 61:
      return 'INDIA'
    case 62:
      return 'IRAQ'
    case 63:
      return 'IRAN (ISLAMIC REPUBLIC OF)'
    case 64:
      return 'ITALY'
    case 65:
      return 'JAMAICA'
    case 66:
      return 'JORDAN'
    case 67:
      return 'JAPAN'
    case 68:
      return 'KENYA'
    case 69:
      return 'KYRGYZSTAN'
    case 70:
      return 'CAMBODIA'
    case 71:
      return 'SAINT KITTS AND NEVIS'
    case 72:
      return 'KOREA (REPUBLIC OF)'
    case 73:
      return 'SOUTH KOREA'
    case 74:
      return 'KUWAIT'
    case 75:
      return 'CAYMAN ISLANDS'
    case 76:
      return 'KAZAKHSTAN'
    case 77:
      return 'LEBANON'
    case 78:
      return 'SRI LANKA'
    case 79:
      return 'LIBERIA'
    case 80:
      return 'LESOTHO'
    case 81:
      return 'LITHUANIA'
    case 82:
      return 'LUXEMBOURG'
    case 83:
      return 'LATVIA'
    case 84:
      return 'LIBYA'
    case 85:
      return 'MOROCCO'
    case 86:
      return 'MONACO'
    case 87:
      return 'MADAGASCAR'
    case 88:
      return 'MARSHALL ISLANDS'
    case 89:
      return 'MALI'
    case 90:
      return 'MYANMAR'
    case 91:
      return 'MONGOLIA'
    case 92:
      return 'MALTA'
    case 93:
      return 'MAURITIUS'
    case 94:
      return 'MALDIVES'
    case 95:
      return 'MALAWI'
    case 96:
      return 'MEXICO'
    case 97:
      return 'MALAYSIA'
    case 98:
      return 'MOZAMBIQUE'
    case 99:
      return 'NIGER'
    case 100:
      return 'NIGERIA'
    case 101:
      return 'NETHERLANDS'
    case 102:
      return 'NORWAY'
    case 103:
      return 'NEW ZEALAND'
    case 104:
      return 'OMAN'
    case 105:
      return 'PANAMA'
    case 106:
      return 'PAPUA NEW GUINEA'
    case 107:
      return 'PHILIPPINES'
    case 108:
      return 'POLAND'
    case 109:
      return 'PORTUGAL'
    case 110:
      return 'QATAR'
    case 111:
      return 'ROMANIA'
    case 112:
      return 'SERBIA'
    case 113:
      return 'RUSSIAN FEDERATION'
    case 114:
      return 'RWANDA'
    case 115:
      return 'SAUDI ARABIA'
    case 116:
      return 'SEYCHELLES'
    case 117:
      return 'SUDAN'
    case 118:
      return 'SWEDEN'
    case 119:
      return 'SINGAPORE'
    case 120:
      return 'SLOVAKIA'
    case 121:
      return 'SIERRA LEONE'
    case 122:
      return 'SENEGAL'
    case 123:
      return 'SOMALIA'
    case 124:
      return 'SCOTLAND'
    case 125:
      return 'SURINAME'
    case 126:
      return 'SOUTH SUDAN'
    case 127:
      return 'TOGO'
    case 128:
      return 'THAILAND'
    case 129:
      return 'TIMOR-LESTE'
    case 130:
      return 'TURKMENISTAN'
    case 131:
      return 'TUNISIA'
    case 132:
      return 'TONGA'
    case 133:
      return 'TURKEY'
    case 134:
      return 'TAIWAN'
    case 135:
      return 'TANZANIA, UNITED REPUBLIC OF'
    case 136:
      return 'UKRAINE'
    case 137:
      return 'UGANDA'
    case 138:
      return 'UNITED STATES OF AMERICA'
    case 139:
      return 'UZBEKISTAN'
    case 140:
      return 'VIET NAM'
    case 141:
      return 'YEMEN'
    case 142:
      return 'SOUTH AFRICA'
    case 143:
      return 'ZAMBIA'
    case 144:
      return 'ZIMBABWE'
    case 145:
      return 'SLOVENIA'
    case 146:
      return 'UNITED KINGDOM'
  }
}

export const getAccountStatus = (accountValue: string) => {
  //   let accountDisplayValue = ''
  //
  //

  return accountMapping[accountValue]

  //
  //   if (accountValue)
  //   {
  //     Object.entries(accountMapping).forEach(([key, value]) => {
  //      if (key == accountValue) {
  //        accountDisplayValue = value
  //      }
  //     })
  //
  //     }
  //   console.log(accountDisplayValue)
  //   abc,'-','-'
  //   return accountDisplayValue
}
